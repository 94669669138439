import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'
import * as Styled from './styles/ProviderListItem.styles'
import { SettingsIcon } from '../Icons'

const ProviderListItem = ({ pk, approvalStatus }) => {
  const { loading, error, data } = useQuery(
    gql`
      query getHealthCareProvider($pk: String!) {
        getHealthCareProvider(pk: $pk, sk: "clinic_info") {
          pk
          sk
          clinic_name
          email
          first_name
          middle_name
          last_name
          city
          state
        }
      }
    `,
    { errorPolicy: 'all', variables: { pk: pk } }
  )

  if (loading) {
    return <p>Loading ...</p>
  }
  if (error) {
    console.log(error.graphQLErrors)
    return <p>Error {error.graphQLErrors} ...</p>
  }
  const item = data.getHealthCareProvider
  return (
    <>
      <Styled.StatusRow>
        <Styled.StatusTag status={approvalStatus}>
          {approvalStatus}
        </Styled.StatusTag>
      </Styled.StatusRow>
      <Styled.ClinicDetailRegion>
        <Styled.FieldColumn>
          <Styled.FieldTitle>Practice Name</Styled.FieldTitle>
          <Styled.FieldBody>{item.clinic_name}</Styled.FieldBody>
        </Styled.FieldColumn>

        <Styled.FieldColumn>
          <Styled.FieldTitle>Email</Styled.FieldTitle>
          <Styled.FieldBody>
            {item.email === '' ? 'N/A' : item.email}
          </Styled.FieldBody>
        </Styled.FieldColumn>

        <Styled.FieldColumn>
          <Styled.FieldTitle>Contact Name</Styled.FieldTitle>
          <Styled.FieldBody>
            {item.first_name}
            {item.middle_name ? ` ${item.middle_name}` : ''} {item.last_name}
          </Styled.FieldBody>
        </Styled.FieldColumn>

        <Styled.FieldColumn>
          <Styled.FieldTitle>City, State</Styled.FieldTitle>
          <Styled.FieldBody>
            {item.city}, {item.state}
          </Styled.FieldBody>
        </Styled.FieldColumn>
        <Styled.EditRecordButton
          to="/admin/edit-provider-record/"
          state={{ userPk: item.pk }}
        >
          Edit Record
          <SettingsIcon />
        </Styled.EditRecordButton>
      </Styled.ClinicDetailRegion>
      <Styled.DividerLine />
    </>
  )
}

export default ProviderListItem
