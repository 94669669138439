import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/Layout'
import ApolloWrapper from '../../components/Auth/ApolloWrapper'
import HcpdPageHero from '../../components/HealthCareProviderDirectory/HcpdPageHero'
import CtaLink from '../../components/ContentBlocks/CtaLink'
import ProviderDirectoryFilterableList from '../../components/Admin/ProviderDIrectoryFilterableList'
import { ProvideAuth } from '../../components/Auth/useAuth'
import AdminRedirectHoc from '../../components/Auth/AdminRedirectHoc'
import ExcelSpreadsheetExportControl from '../../components/Admin/ExcelSpreadsheetExportControl'
import SiteRebuildControl from '../../components/Admin/SiteRebuildControl'
import { HeroBody } from '../../components/HealthCareProviderDirectory/styles/HcpdPageHero'

export const query = graphql`
  {
    prismicHcpdAdminPanel {
      data {
        title {
          text
        }
        body_text {
          richText
        }
      }
    }
  }
`

const HcpdAdminPage = ({ data }) => {
  const doc = data.prismicHcpdAdminPanel?.data
  if (!doc) {
    return null
  }

  return (
    <ProvideAuth>
      <AdminRedirectHoc>
        <ApolloWrapper>
          <Layout>
            <HcpdPageHero
              title={doc.title?.text ?? 'New Registration'}
              body={doc.body_text}
            >
              <CtaLink
                text="Register a New Provider"
                link={{
                  link_type: 'Document',
                  type: 'admin',
                  uid: 'register-new-provider',
                }}
              />
              <SiteRebuildControl />
              <HeroBody>
                See all pages with Content Expirations (e.g., pages that require
                a medical review).
              </HeroBody>
              <CtaLink
                text="View Expiring Pages"
                link={{
                  link_type: 'Document',
                  type: 'admin',
                  uid: 'content-expiry',
                }}
              />

              <ExcelSpreadsheetExportControl />
            </HcpdPageHero>
            <ProviderDirectoryFilterableList />
          </Layout>
        </ApolloWrapper>
      </AdminRedirectHoc>
    </ProvideAuth>
  )
}

export default HcpdAdminPage
