import styled from 'styled-components'

import { DEVICE } from '../../../util/constants'
import { LabelFieldColumn } from '../../HealthCareProviderDirectory/styles/HcpdLandingFilterableList.styles'

export const ProviderDirectoryHeading = styled.h2`
  grid-column: 2 / 3;
  margin-top: 34px;
  font-style: normal;
  font-weight: 300;
  font-size: 4.8rem;
  line-height: 1.35;
  color: var(--highlight-blue);
`

export const ListContainer = styled.div`
  grid-column: 2 / 3;
  display: flex;
  flex-direction: column;
`

export const AdminLabelFieldColumn = styled(LabelFieldColumn)`
  /* naming convenience */
`

export const AdminSearchColumn = styled(AdminLabelFieldColumn)`
  @media ${DEVICE.tabletL} {
    flex: 0 0;
    flex-basis: 400px;
    margin-right: unset;
  }
`

export const SearchField = styled.input`
  padding-left: 18px;
  background-color: var(--content-background-light);
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: 0.02em;
  height: 42px;
  width: 400px;

  ::placeholder {
    color: var(--content-background-dark-blue);
  }
`
