import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import gql from 'graphql-tag'

import ProviderListItem from './ProviderListItem'

const ITEMS_PER_PAGE = 250

const FILTERED_PROVIDER_LIST_QUERY = gql`
  query queryByApprovalStatusGSI(
    $approval_status: String
    $first: Int
    $after: String
  ) {
    queryByApprovalStatusGSI(
      approval_status: $approval_status
      first: $first
      after: $after
    ) {
      items {
        approval_status
        pk
      }
    }
  }
`

const SEARCH_PROVIDER_LIST_BY_CLINIC_NAME = gql`
  query listHealthCareProviders(
    $search_query: String
    $approval_status: String
  ) {
    listHealthCareProviders(
      filter: {
        slug: { contains: $search_query }
        approval_status: { contains: $approval_status }
      }
      limit: 2000
    ) {
      items {
        approval_status
        slug
        pk
      }
    }
  }
`

const FilteredProviderList = ({ filter, searchString }) => {
  const {
    loading: filterLoading,
    error: filterError,
    data: filterData,
  } = useQuery(FILTERED_PROVIDER_LIST_QUERY, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    variables: { approval_status: filter, first: ITEMS_PER_PAGE },
  })

  const {
    loading: searchLoading,
    error: searchError,
    data: searchData,
  } = useQuery(SEARCH_PROVIDER_LIST_BY_CLINIC_NAME, {
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    variables: { search_query: searchString, approval_status: filter },
  })

  // useEffect(() => {
  //   if (!filterData) { return }
  //   if (!filterData.queryByApprovalStatusGSI.nextToken) { return }
  //   setNextToken(filterData.queryByApprovalStatusGSI.nextToken)
  // }, [filterData])

  // useEffect(() => {
  //   if (!searchData) { return }
  //   if (!searchData.listHealthCareProviders.nextToken) { return }
  //   setNextToken(searchData.listHealthCareProviders.nextToken)
  // }, [searchData])

  if (!searchString && filterLoading) {
    return <p>Loading ...</p>
  }
  if (!searchString && filterError) {
    return <p>Error {filterError.graphQLErrors} ...</p>
  }
  const items = filterData.queryByApprovalStatusGSI.items

  if (searchString && searchLoading) {
    return <p>Loading ...</p>
  }
  if (searchString && searchError) {
    return <p>Error {searchError.graphQLErrors} ...</p>
  }
  let searchResults
  if (searchString && searchData) {
    searchResults = searchData?.listHealthCareProviders?.items ?? null
  }
  // console.log(searchResults)

  return (
    <>
      {/* <pre>{JSON.stringify(searchResults, null, 4)}</pre> */}
      {!searchResults
        ? items.map(item => (
            <ProviderListItem
              key={item.pk}
              pk={item.pk}
              approvalStatus={item.approval_status}
            />
          ))
        : searchResults.map(searchItem => (
            <ProviderListItem
              key={searchItem.pk}
              pk={searchItem.pk}
              approvalStatus={searchItem.approval_status}
            />
          ))}
    </>
  )
}

export default FilteredProviderList
