import React, { useState } from 'react'
import { HeroBody } from '../../components/HealthCareProviderDirectory/styles/HcpdPageHero'
import CtaLink from '../../components/ContentBlocks/CtaLink'
import { useAuth } from '../Auth/useAuth'

const LAMBDA_URL =
  'https://q3p32rjalbdro2nc6pogjaqrom0pbndj.lambda-url.us-west-2.on.aws/'

const API_STATUS = {
  none: 'NONE',
  pending: 'PENDING',
  resolved: 'RESOLVED',
  error: 'ERROR',
}

const ExcelSpreadsheetExportControl = () => {
  const [excelApiStatus, setExcelApiStatus] = useState(API_STATUS.none)
  const [url, setUrl] = useState(null)
  const auth = useAuth()

  const trigger = async email => {
    const data = {
      emailAddress: email,
    }

    setExcelApiStatus(API_STATUS.pending)
    const response = await fetch(LAMBDA_URL, {
      method: 'POST',
      body: JSON.stringify(data),
    })
    console.log(response)
    if (response.ok) {
      const responseBody = await response.json()
      if (responseBody && responseBody.url) {
        setUrl(responseBody.url)
        setExcelApiStatus(API_STATUS.resolved)
      } else {
        console.error('URL is not present in the response')
        setExcelApiStatus(API_STATUS.error)
      }
    } else {
      setExcelApiStatus(API_STATUS.error)
    }
  }

  if (excelApiStatus === API_STATUS.pending) {
    return (
      <HeroBody style={{ color: '#f29f05' }}>
        The Excel spreadsheet is now being generated. This may take several
        minutes. When ready, a link to download will appear here.
      </HeroBody>
    )
  }

  if (excelApiStatus === API_STATUS.error) {
    return (
      <HeroBody style={{ color: '#3771cf' }}>
        There has been an unknown error. Please contact the support team.
      </HeroBody>
    )
  }

  if (excelApiStatus === API_STATUS.resolved) {
    return (
      <HeroBody style={{ color: 'green' }}>
        The Excel spreadsheet has been successfully generated. Please click{' '}
        <a href={url} download>
          here
        </a>{' '}
        to download.
      </HeroBody>
    )
  }

  return (
    <>
      <HeroBody>
        Export the Health Care Provider Directory database as an Excel
        spreadsheet.
      </HeroBody>
      <CtaLink
        text="Export HCPD"
        link={{
          link_type: 'Function',
          function: () => trigger(auth.user.attributes.email),
        }}
      />
    </>
  )
}

export default ExcelSpreadsheetExportControl
