import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'
import { Link } from 'gatsby'

export const StatusRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 30px 0 8px 0;
`

export const StatusTag = styled.div`
  height: 24px;
  background: ${({ status }) =>
    status === 'PENDING'
      ? 'var(--graphic-accent-orange-2)'
      : status === 'APPROVED'
      ? 'var(--graphic-accent-blue-1)'
      : 'var(--content-background-dark-blue)'};
  font-style: normal;
  font-weight: bold;
  font-size: 1.2rem;
  line-height: 1.33;
  color: white;
  padding: 3px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ClinicDetailRegion = styled.div`
  display: flex;
  flex-direction: column;

  @media ${DEVICE.tabletL} {
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
  }
`

export const FieldColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 10px 0;

  @media ${DEVICE.tabletL} {
    flex: 0 0 181px;
    max-width: 181px;
    margin-right: 56px;
  }
`

export const FieldTitle = styled.h4`
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.71;
  letter-spacing: 0.02em;
  color: var(--highlight-blue);
`

export const FieldBody = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.2;
  color: var(--content-background-dark-blue);
`

export const EditRecordButton = styled(Link)`
  text-decoration-line: none;
  width: 132px;
  height: 44px;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.71;
  border: 1px solid var(--highlight-blue);
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  color: var(--highlight-blue);
  background-color: white;
  margin: 20px 0;

  svg .rollover {
    display: none;
  }

  :hover {
    color: white;
    background-color: var(--highlight-blue);

    svg .rollover {
      display: inherit;
    }

    svg .normal {
      display: none;
    }
  }

  @media ${DEVICE.tabletL} {
    margin-top: unset;
  }
`

export const DividerLine = styled.hr`
  border: 1px solid var(--highlight-blue);
  margin-top: 14px;
`
