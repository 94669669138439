import React, { useState, useEffect } from 'react'
import Select from 'react-select'

import { RegionContainer } from '../styles/Utility.styles'
import FilterRow from '../FilterRow'
import * as Styled from './styles/ProviderDIrectoryFilterableList.styles'
// eslint-disable-next-line no-unused-vars
import { FieldLabel } from '../HealthCareProviderDirectory/styles/HcpdLandingFilterableList.styles'
import { DROPDOWN_STYLES } from '../../util/constants'
import FilteredProvidersList from './FilteredProvidersList'
import {
  getStorageString,
  setStorageString,
  removeStorageString,
  StorageKey,
  StorageType,
} from '../../util/storage'

const DROPDOWN_OPTIONS = [
  { value: 'PENDING', label: 'Pending' },
  { value: 'APPROVED', label: 'Approved' },
  { value: 'DENIED', label: 'Denied' },
]

const AdminHcpdFilterRow = ({
  activeFilter,
  setActiveFilter,
  setSearchString,
}) => {
  const [searchInput, setSearchInput] = useState(null)

  const evaluateKey = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
      setSearchString(searchInput?.toLowerCase().replace(' ', '-'))
    }
  }

  return (
    <FilterRow>
      <Styled.AdminLabelFieldColumn width="240px">
        <FieldLabel>Provider Status</FieldLabel>
        <Select
          options={DROPDOWN_OPTIONS}
          value={activeFilter}
          styles={DROPDOWN_STYLES}
          height="42px"
          onChange={e => {
            setActiveFilter({ value: e.value, label: e.label })
            setSearchString('')
            setSearchString(null)
          }}
        />
      </Styled.AdminLabelFieldColumn>
      <Styled.AdminSearchColumn>
        {activeFilter.value === 'APPROVED' && (
          <>
            <FieldLabel>Search by Provider or Practice Name</FieldLabel>
            <form>
              <Styled.SearchField
                placeholder="Enter Search Term"
                onChange={e => setSearchInput(e.target.value)}
                onKeyDown={e => evaluateKey(e)}
                onBlur={() =>
                  setSearchString(searchInput?.toLowerCase().replace(' ', '-'))
                }
              />
            </form>
          </>
        )}
      </Styled.AdminSearchColumn>
    </FilterRow>
  )
}

const ProviderDirectoryFilterableList = () => {
  const [activeFilter, setActiveFilter] = useState({
    value: DROPDOWN_OPTIONS[0].value,
    label: DROPDOWN_OPTIONS[0].label,
  })
  const [searchString, setSearchString] = useState(null)

  // const [token, setToken] = useState('')
  // const [nextToken, setNextToken] = useState('')
  const hasPreviousApprovalFilter = getStorageString({
    key: StorageKey.HCPD_ADMIN_APPROVAL_FILTER,
    storageType: StorageType.SESSION,
  })
  // const hasToken = getStorageString({
  //   key: StorageKey.HCPD_ADMIN_GQL_TOKEN,
  //   storageType: StorageType.SESSION
  // })

  useEffect(() => {
    if (hasPreviousApprovalFilter) {
      setActiveFilter(JSON.parse(hasPreviousApprovalFilter))
    }
    // if (hasToken) {
    //   setToken(hasToken)
    // }
  }, [getStorageString])

  useEffect(() => {
    // if (hasToken) {
    //   removeStorageString({
    //     key: StorageKey.HCPD_ADMIN_GQL_TOKEN,
    //     storageType: StorageType.SESSION
    //   })
    // }
    if (activeFilter.value === DROPDOWN_OPTIONS[0].value) {
      if (hasPreviousApprovalFilter) {
        removeStorageString({
          key: StorageKey.HCPD_ADMIN_APPROVAL_FILTER,
          storageType: StorageType.SESSION,
        })
      }
      return
    }
    setStorageString({
      key: StorageKey.HCPD_ADMIN_APPROVAL_FILTER,
      value: JSON.stringify(activeFilter),
      storageType: StorageType.SESSION,
    })
  }, [activeFilter])

  // useEffect(() => {
  //   if (nextToken === '') {
  //     if (hasToken) {
  //       removeStorageString({
  //         key: StorageKey.HCPD_ADMIN_GQL_TOKEN,
  //         storageType: StorageType.SESSION
  //       })
  //     }
  //     return
  //   }
  //   setStorageString({
  //     key: StorageKey.HCPD_ADMIN_GQL_TOKEN,
  //     value: nextToken,
  //     storageType: StorageType.SESSION
  //   })
  // }, [nextToken])

  return (
    <>
      <RegionContainer>
        <Styled.ProviderDirectoryHeading>
          Provider Directory
        </Styled.ProviderDirectoryHeading>
      </RegionContainer>
      <AdminHcpdFilterRow
        activeFilter={activeFilter}
        setActiveFilter={setActiveFilter}
        setSearchString={setSearchString}
      />
      <RegionContainer>
        <Styled.ListContainer>
          <FilteredProvidersList
            filter={activeFilter.value}
            searchString={searchString}
            // token={token}
            // setNextToken={setNextToken}
          />
        </Styled.ListContainer>
      </RegionContainer>
    </>
  )
}

export default ProviderDirectoryFilterableList
