import styled from 'styled-components'
import { DEVICE } from '../../../util/constants'

import LOCATION_SEARCH_ICON from '../../../images/location-search-icon.svg'

export const LabelFieldColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  margin-bottom: 16px;

  @media ${DEVICE.tabletL} {
    margin-right: 14px;
    flex-basis: ${({ width }) => width};
  }

  :last-of-type {
    @media ${DEVICE.tabletL} {
      flex: 1 0;
      margin-right: unset;
    }
  }
`

export const FieldLabel = styled.label`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.7;
  letter-spacing: 0.02em;
  color: var(--content-background-dark-blue);
  padding-bottom: 5px;
`

export const LocationSearchField = styled.input`
  /* width: 100%; */
  background: url(${LOCATION_SEARCH_ICON}) center left 16px no-repeat;
  padding-left: 46px;
  background-color: var(--content-background-light);
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 1.3rem;
  line-height: 1.71;
  letter-spacing: 0.02em;
  height: 42px;

  ::placeholder {
    color: var(--content-background-dark-blue);
  }
`
