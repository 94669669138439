import React, { useState } from 'react'
import { HeroBody } from '../HealthCareProviderDirectory/styles/HcpdPageHero'
import CtaLink from '../ContentBlocks/CtaLink'

const REBUILD_WEBHOOK_URL =
  'https://3rvolhuohqk7efi7skug7nwr7q0klxvj.lambda-url.us-west-2.on.aws/'

const API_STATUS = {
  none: 'NONE',
  pending: 'PENDING',
  resolved: 'RESOLVED',
  error: 'ERROR',
}

const SiteRebuildControl = () => {
  const [webhookResponseStatus, setWebhookResponseStatus] = useState(
    API_STATUS.none
  )

  const triggerRebuild = async () => {
    setWebhookResponseStatus(API_STATUS.pending)
    // const response = await fetch(REBUILD_WEBHOOK_URL)
    const response = await fetch(REBUILD_WEBHOOK_URL)
    console.log(response)
    if (response.ok) {
      setWebhookResponseStatus(API_STATUS.resolved)
    } else {
      setWebhookResponseStatus(API_STATUS.error)
    }
  }

  let heroBlock = (
    <HeroBody>
      Manually trigger a site rebuild. This should be done after approving,
      removing, or editing Health Care Provider Directory entries.
    </HeroBody>
  )

  if (webhookResponseStatus === API_STATUS.pending) {
    heroBlock = (
      <HeroBody style={{ color: '#f29f05' }}>
        The site rebuild request has been sent.
      </HeroBody>
    )
  }

  if (webhookResponseStatus === API_STATUS.error) {
    heroBlock = (
      <HeroBody style={{ color: '#3771cf' }}>
        There has been an unknown error. Please contact the support team.
      </HeroBody>
    )
  }

  if (webhookResponseStatus === API_STATUS.resolved) {
    heroBlock = (
      <HeroBody style={{ color: 'green' }}>
        The site rebuild has been successfully triggered. Latest edits should be
        live in approximately 8 minutes.
      </HeroBody>
    )
  }

  return (
    <>
      {heroBlock}
      <CtaLink
        text="Trigger Site Rebuild"
        link={{
          link_type: 'Function',
          function: () => triggerRebuild(),
        }}
      />
    </>
  )
}

export default SiteRebuildControl
